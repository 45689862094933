import cn from 'clsx';
import memoize from 'nano-memoize';
import equals from 'react-fast-compare';

import { computeResponsiveClasses, Responsive } from './responsive';
import { EnhancerFn } from './types';

export type SpaceVals =
  | 'auto'
  | 'px'
  | 0
  | 0.5
  | 1
  | 1.5
  | 2
  | 2.5
  | 3
  | 3.5
  | 4
  | 4.5
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 14
  | 16
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64
  | 72
  | 80
  | 96;

export type NegativeSpaceVals =
  | '-px'
  | -0
  | -0.5
  | -1
  | -1.5
  | -2
  | -2.5
  | -3
  | -3.5
  | -4
  | -4.5
  | -5
  | -6
  | -7
  | -8
  | -9
  | -10
  | -11
  | -12
  | -14
  | -16
  | -20
  | -24
  | -32
  | -40
  | -60
  | -80;

export interface IMarginProps {
  m?: SpaceVals | NegativeSpaceVals | Responsive<SpaceVals | NegativeSpaceVals>;
  mx?: SpaceVals | NegativeSpaceVals | Responsive<SpaceVals | NegativeSpaceVals>;
  my?: SpaceVals | NegativeSpaceVals | Responsive<SpaceVals | NegativeSpaceVals>;
  mt?: SpaceVals | NegativeSpaceVals | Responsive<SpaceVals | NegativeSpaceVals>;
  mr?: SpaceVals | NegativeSpaceVals | Responsive<SpaceVals | NegativeSpaceVals>;
  mb?: SpaceVals | NegativeSpaceVals | Responsive<SpaceVals | NegativeSpaceVals>;
  ml?: SpaceVals | NegativeSpaceVals | Responsive<SpaceVals | NegativeSpaceVals>;
}

export interface IPaddingProps {
  p?: SpaceVals | Responsive<SpaceVals>;
  px?: SpaceVals | Responsive<SpaceVals>;
  py?: SpaceVals | Responsive<SpaceVals>;
  pt?: SpaceVals | Responsive<SpaceVals>;
  pr?: SpaceVals | Responsive<SpaceVals>;
  pb?: SpaceVals | Responsive<SpaceVals>;
  pl?: SpaceVals | Responsive<SpaceVals>;
}

export interface ISpacingProps extends IMarginProps, IPaddingProps {}

export const spacingPropNames: Array<keyof ISpacingProps> = [
  'm',
  'mb',
  'ml',
  'mr',
  'mt',
  'mx',
  'my',
  'p',
  'pb',
  'pl',
  'pr',
  'pt',
  'px',
  'py',
];

export const marginProps: EnhancerFn<IMarginProps> = (props: IMarginProps) => {
  const { m, mx, my, mt, mr, mb, ml, ...rest } = props;

  return { props: rest, className: _marginProps(m, mx, my, mt, mr, mb, ml) };
};

const _marginProps = memoize(
  (
    m: IMarginProps['m'],
    mx: IMarginProps['mx'],
    my: IMarginProps['my'],
    mt: IMarginProps['mt'],
    mr: IMarginProps['mr'],
    mb: IMarginProps['mb'],
    ml: IMarginProps['ml'],
  ) => {
    return cn({
      [computeResponsiveClasses('m', m)]: m !== void 0,
      [computeResponsiveClasses('mx', mx)]: mx !== void 0,
      [computeResponsiveClasses('my', my)]: my !== void 0,
      [computeResponsiveClasses('mt', mt)]: mt !== void 0,
      [computeResponsiveClasses('mr', mr)]: mr !== void 0,
      [computeResponsiveClasses('mb', mb)]: mb !== void 0,
      [computeResponsiveClasses('ml', ml)]: ml !== void 0,
    });
  },
  { maxAge: Infinity, equals },
);

export const paddingProps: EnhancerFn<IPaddingProps> = (props: IPaddingProps) => {
  const { p, px, py, pt, pr, pb, pl, ...rest } = props;

  return { props: rest, className: _paddingProps(p, px, py, pt, pr, pb, pl) };
};

const _paddingProps = memoize(
  (
    p: IPaddingProps['p'],
    px: IPaddingProps['px'],
    py: IPaddingProps['py'],
    pt: IPaddingProps['pt'],
    pr: IPaddingProps['pr'],
    pb: IPaddingProps['pb'],
    pl: IPaddingProps['pl'],
  ) => {
    return cn({
      [computeResponsiveClasses('p', p)]: p !== void 0,
      [computeResponsiveClasses('px', px)]: px !== void 0,
      [computeResponsiveClasses('py', py)]: py !== void 0,
      [computeResponsiveClasses('pt', pt)]: pt !== void 0,
      [computeResponsiveClasses('pr', pr)]: pr !== void 0,
      [computeResponsiveClasses('pb', pb)]: pb !== void 0,
      [computeResponsiveClasses('pl', pl)]: pl !== void 0,
    });
  },
  { maxAge: Infinity, equals },
);

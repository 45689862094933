import cn from 'clsx';
import memoize from 'nano-memoize';
import equals from 'react-fast-compare';

import { computeResponsiveClasses, isResponsiveObject, Responsive } from './responsive';
import { EnhancerFn } from './types';

export type FlexVals = 1 | 'auto' | 'initial' | 'none';
export type FlexDirectionVals = 'row' | 'row-reverse' | 'col' | 'col-reverse';
export type FlexWrapVals = true | 'reverse' | 'no-wrap';
export type FlexGrowVals = true | 0;
export type FlexShrinkVals = true | 0;
export type JustifyContentVals = 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
export type JustifyItemsVals = 'auto' | 'start' | 'end' | 'center' | 'stretch';
export type JustifySelfVals = 'auto' | 'start' | 'end' | 'center' | 'stretch';
export type AlignContentVals = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
export type AlignItemsVals = 'start' | 'end' | 'center' | 'baseline' | 'stretch';
export type AlignSelfVals = 'auto' | 'flex-start' | 'flex-end' | 'center' | 'stretch';

export interface IFlexProps {
  flex?: FlexVals | Responsive<FlexVals>;
  flexDirection?: FlexDirectionVals | Responsive<FlexDirectionVals>;
  flexWrap?: FlexWrapVals | Responsive<FlexWrapVals>;
  flexGrow?: FlexGrowVals | Responsive<FlexGrowVals>;
  flexShrink?: FlexShrinkVals | Responsive<FlexShrinkVals>;
  justifyContent?: JustifyContentVals | Responsive<JustifyContentVals>;
  justifyItems?: JustifyItemsVals | Responsive<JustifyItemsVals>;
  justifySelf?: JustifySelfVals | Responsive<JustifySelfVals>;
  alignContent?: AlignContentVals | Responsive<AlignContentVals>;
  alignItems?: AlignItemsVals | Responsive<AlignItemsVals>;
  alignSelf?: AlignSelfVals | Responsive<AlignSelfVals>;
}

export interface IFlexShorthandProps {
  align?: IFlexProps['alignItems'];
  justify?: IFlexProps['justifyContent'];
  wrap?: IFlexProps['flexWrap'];
  direction?: IFlexProps['flexDirection'];
  grow?: IFlexProps['flexGrow'];
  shrink?: IFlexProps['flexShrink'];
}

export const flexPropNames: Array<keyof IFlexProps> = [
  'flex',
  'flexDirection',
  'flexWrap',
  'flexGrow',
  'flexShrink',
  'justifyContent',
  'justifyItems',
  'justifySelf',
  'alignContent',
  'alignItems',
  'alignSelf',
];

export const flexProps: EnhancerFn<IFlexProps> = (props: IFlexProps) => {
  const {
    flex,
    flexDirection,
    flexWrap,
    flexGrow,
    flexShrink,
    justifyContent,
    justifyItems,
    justifySelf,
    alignContent,
    alignItems,
    alignSelf,
    ...rest
  } = props;

  return {
    props: rest,
    className: _flexProps(
      flex,
      flexDirection,
      flexWrap,
      flexGrow,
      flexShrink,
      justifyContent,
      justifyItems,
      justifySelf,
      alignContent,
      alignItems,
      alignSelf,
    ),
  };
};

//Function to map FlexWrap properties values to their respective tailwind class
const flexWrapProp = (flexWrap: IFlexProps['flexWrap']) => {
  if (isResponsiveObject(flexWrap)) {
    const newResponsiveProp: Responsive<IFlexProps['flexWrap']> = {};

    for (const [key, value] of Object.entries(flexWrap)) {
      newResponsiveProp[key] = value === true ? 'wrap' : value === 'reverse' ? 'wrap-reverse' : value;
    }

    return newResponsiveProp;
  } else if (flexWrap) {
    return flexWrap === true ? 'wrap' : flexWrap === 'reverse' ? 'wrap-reverse' : flexWrap;
  }
};

//Function to map Align properties values for flex-end, flex-start,space-between, space-around, and space-evenly to their respective tailwind class
const alignmentProps = (prop: IFlexProps['alignContent'] | IFlexProps['alignSelf']) => {
  if (isResponsiveObject(prop)) {
    const newResponsiveProp: Responsive<IFlexProps['alignContent'] | IFlexProps['alignSelf']> = {};

    for (const [key, value] of Object.entries(prop)) {
      const valueParts = value.split('-');
      newResponsiveProp[key] = valueParts.length === 1 ? value : valueParts[1];
    }

    return newResponsiveProp;
  } else if (prop) {
    const nameParts = prop.split('-');
    return nameParts.length === 1 ? prop : nameParts[1];
  }
};

const _flexProps = memoize(
  (
    flex: IFlexProps['flex'],
    flexDirection: IFlexProps['flexDirection'],
    flexWrap: IFlexProps['flexWrap'],
    flexGrow: IFlexProps['flexGrow'],
    flexShrink: IFlexProps['flexShrink'],
    justifyContent: IFlexProps['justifyContent'],
    justifyItems: IFlexProps['justifyItems'],
    justifySelf: IFlexProps['justifySelf'],
    alignContent: IFlexProps['alignContent'],
    alignItems: IFlexProps['alignItems'],
    alignSelf: IFlexProps['alignSelf'],
  ) => {
    return cn({
      [computeResponsiveClasses('flex', flex)]: flex !== void 0,
      [computeResponsiveClasses('flex', flexDirection)]: flexDirection !== void 0,

      [computeResponsiveClasses('flex', flexWrapProp(flexWrap))]: flexWrap !== void 0,
      [computeResponsiveClasses('flex-grow', flexGrow === true ? true : isResponsiveObject(flexGrow) ? flexGrow : '0')]:
        flexGrow !== void 0,
      [computeResponsiveClasses(
        'flex-shrink',
        flexShrink === true ? true : isResponsiveObject(flexShrink) ? flexShrink : '0',
      )]: flexShrink !== void 0,

      [computeResponsiveClasses('justify', justifyContent)]: justifyContent !== void 0,
      [computeResponsiveClasses('justify-items', justifyItems)]: justifyItems !== void 0,
      [computeResponsiveClasses('justify-self', justifySelf)]: justifySelf !== void 0,

      [computeResponsiveClasses('content', alignmentProps(alignContent))]: alignContent !== void 0,
      [computeResponsiveClasses('items', alignItems)]: alignItems !== void 0,
      [computeResponsiveClasses('self', alignmentProps(alignSelf))]: alignSelf !== void 0,
    });
  },
  { maxAge: Infinity, equals },
);

import cn from 'clsx';

import { buildClassname } from './pseudo';
import { PrimitiveValue } from './types';

type ResponsiveProps = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export type Responsive<T> = {
  [key in ResponsiveProps | 'default']?: T;
};

export const isResponsiveObject = (obj: any): obj is Responsive<PrimitiveValue> => obj && typeof obj === 'object';

export function computeResponsiveClasses<T = unknown>(prop: string, val: PrimitiveValue | Responsive<T | unknown> | T) {
  let classes = {};

  // if val is literal true value, then we're trying to use default and do not need to append a value
  // val can be 0, account for this
  if (isResponsiveObject(val)) {
    classes = {
      [buildClassname(prop, val.default)]: val.default || val.default === 0,
      [`sm:${buildClassname(prop, val.sm)}`]: val.sm || val.sm === 0,
      [`md:${buildClassname(prop, val.md)}`]: val.md || val.md === 0,
      [`lg:${buildClassname(prop, val.lg)}`]: val.lg || val.lg === 0,
      [`xl:${buildClassname(prop, val.xl)}`]: val.xl || val.xl === 0,
      [`2xl:${buildClassname(prop, val['2xl'])}`]: val['2xl'] || val['2xl'] === 0,
    };
  } else {
    classes[buildClassname(prop, val as PrimitiveValue)] = val !== void 0;
  }

  return cn(classes);
}

import cn from 'clsx';
import memoize from 'nano-memoize';
import equals from 'react-fast-compare';

import { computePseudoClasses, Pseudo } from './pseudo';
import { computeResponsiveClasses, Responsive } from './responsive';
import { NegativeSpaceVals, SpaceVals } from './spacing';
import { EnhancerFn } from './types';

type PositionVals = 'static' | 'fixed' | 'absolute' | 'relative' | 'sticky';
type PositionLocVals = 'auto' | SpaceVals | NegativeSpaceVals;
type PinVals = true | PositionLocVals;
type ZIndexVals = -1 | 0 | 10 | 20 | 40 | 50 | 'auto';

export interface IPositionProps {
  pos?: PositionVals | Responsive<PositionVals>;
  pin?: PinVals | Responsive<PinVals>;
  pinY?: PinVals | Responsive<PinVals>;
  pinX?: PinVals | Responsive<PinVals>;
  top?: PositionLocVals | Responsive<PositionLocVals>;
  left?: PositionLocVals | Responsive<PositionLocVals>;
  right?: PositionLocVals | Responsive<PositionLocVals>;
  bottom?: PositionLocVals | Responsive<PositionLocVals>;
  zIndex?: ZIndexVals | Pseudo<ZIndexVals, 'focus'>;
}

export const positionPropNames: Array<keyof IPositionProps> = [
  'bottom',
  'pin',
  'pinX',
  'pinY',
  'left',
  'pos',
  'right',
  'top',
  'zIndex',
];

export const positionProps: EnhancerFn<IPositionProps> = (props: IPositionProps) => {
  const { pos, pin, pinY, pinX, top, left, right, bottom, zIndex, ...rest } = props;

  return { props: rest, className: _positionProps(pos, pin, pinY, pinX, top, left, right, bottom, zIndex) };
};

const _positionProps = memoize(
  (
    pos: IPositionProps['pos'],
    pin: IPositionProps['pin'],
    pinY: IPositionProps['pinY'],
    pinX: IPositionProps['pinX'],
    top: IPositionProps['top'],
    left: IPositionProps['left'],
    right: IPositionProps['right'],
    bottom: IPositionProps['bottom'],
    zIndex: IPositionProps['zIndex'],
  ) => {
    return cn(
      {
        [computeResponsiveClasses('', pos)]: pos !== void 0,
        [computeResponsiveClasses('inset', pin === true ? '0' : pin)]: pin !== void 0,
        [computeResponsiveClasses('inset-y', pinY === true ? '0' : pinY)]: pinY !== void 0,
        [computeResponsiveClasses('inset-x', pinX === true ? '0' : pinX)]: pinX !== void 0,
        [computeResponsiveClasses('top', top)]: top !== void 0,
        [computeResponsiveClasses('left', left)]: left !== void 0,
        [computeResponsiveClasses('right', right)]: right !== void 0,
        [computeResponsiveClasses('bottom', bottom)]: bottom !== void 0,
      },
      computePseudoClasses('z', zIndex),
    );
  },
  { maxAge: Infinity, equals },
);

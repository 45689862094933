import { useFocusRing } from '@react-aria/focus';
import { useListBox, useOption } from '@react-aria/listbox';
import { mergeProps } from '@react-aria/utils';
import { Item } from '@react-stately/collections';
import { ListProps, ListState, useListState } from '@react-stately/list';
import { Node } from '@react-types/shared';
import * as React from 'react';

import { splitBoxProps } from '../../utils';
import { Box, BoxOwnProps } from '../Box';

export type ListBoxProps<T> = ListProps<T> & BoxOwnProps;

function ListBoxBase<T extends object>(props: ListBoxProps<T>, forwardRef: React.RefObject<HTMLDivElement>) {
  const { matchedProps, remainingProps } = splitBoxProps(props);
  const domRef = React.useRef();
  const ref = forwardRef || domRef;
  const state = useListState<T>(props);
  const { listBoxProps } = useListBox(remainingProps, state, ref);

  return (
    <Box {...listBoxProps} {...matchedProps} color={matchedProps.color} ref={ref}>
      {[...state.collection].map(item => (
        <ListBoxOption key={item.key} item={item} state={state} />
      ))}
    </Box>
  );
}

// forwardRef doesn't support generic parameters, so cast the result to the correct type
// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
export const ListBox = React.forwardRef(ListBoxBase) as <T>(
  props: ListBoxProps<T> & { ref?: React.RefObject<HTMLDivElement> },
) => React.ReactElement;

function ListBoxOption<T>({ item, state }: { item: Node<T>; state: ListState<unknown> }) {
  const { rendered, key } = item;
  const isDisabled = state.disabledKeys.has(key);

  const ref = React.useRef();
  const { optionProps } = useOption(
    {
      key,
      isDisabled,
    },
    state,
    ref,
  );

  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <Box
      {...mergeProps(optionProps, focusProps)}
      bg={{ default: isFocusVisible ? 'canvas-200' : 'transparent', hover: 'canvas-200' }}
      color="body"
      cursor="pointer"
      ref={ref}
    >
      {rendered}
    </Box>
  );
}

export const ListBoxItem = Item;
